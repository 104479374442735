<template>
    <div class="scope-upload-contract">
        <h3 class="fw-bold">
            <img src="@/assets/images/crown.svg" alt="crown" class="mr-2"/>
            Contract
        </h3>
        <p class="text-information small">The contract is required before the flowdown process can start</p>
        <div class="row py-1 small fw-bold">
            <div class="col-4">Document Name</div>
            <div class="col-2">Status</div>
            <div class="col-2">Due Date</div>
            <div class="col-4">Upload/Sign</div>
        </div>
        <div class="row py-1 data">
            <div class="col-4">
                <router-link :to="{ name: 'TaskContracts', params: { id: projectId, task_id: task.id }}">
                    {{ task.file_name_without_extension }}
                </router-link>
            </div>
            <div class="col-2">
                <TaskStatusBadge :task="task"/>
            </div>
            <div class="col-2">{{ formatDate(task.due_date) }}</div>
            <div class="col-4">
                <TaskPageDocumentFileArea v-if="isPendingExternalSignature" color="blue" :task-type="task.type"
                                          :upload="true" :submit-button="false"
                                          @change="file => toggleTaskESignatureModal(file)"/>

                <TaskPageDocumentFileArea v-if="isFullyExecuted" color="green" :document-name="task.file_name"
                                          @linkClicked="toggleDocumentPreviewer"/>

                <CButton v-if="isReadyForInternalSignature" color="primary" @click="toggleTaskESignatureModal(null)">
                    Sign the contract
                </CButton>

                <TaskReminderButton :task="task"/>
            </div>
        </div>
    </div>
    <TaskESignatureModal :visible="taskESignatureModal" :task="task" :file="taskESignatureModalFile"
                         @close="toggleTaskESignatureModal(null)" @sign="updateEvent" @upload="updateEvent"/>
    <DocumentPreviewer :visible="documentPreviewer" type="tasks" :type-id="task.id" route="preview-uploaded-file"
                       :document-name="task?.file_name" @closed="toggleDocumentPreviewer"/>
</template>

<script>
import taskHelper from "@/mixin/taskHelper";
import TaskStatusBadge from "@/components/Task/TaskStatusBadge.vue";
import dateFormater from "@/mixin/dateFormater";
import TaskPageDocumentFileArea from "@/components/TaskPages/TaskPageDocumentFileArea.vue";
import TaskESignatureModal from "@/components/TaskPages/TaskESignatureModal.vue";
import DocumentPreviewer from "@/components/Documents/DocumentPreviewer.vue";
import TaskReminderButton from "@/components/Task/TaskReminderButton.vue";

export default {
    name: 'ScopeUploadContract',
    components: {TaskReminderButton, DocumentPreviewer, TaskESignatureModal, TaskPageDocumentFileArea, TaskStatusBadge},
    mixins: [taskHelper, dateFormater],
    emits: ['update'],
    props: {
        task: Object,
        projectId: Number,
    },
    data() {
        return {
            documentPreviewer: false,
            taskESignatureModal: false,
            taskESignatureModalFile: null,
        }
    },
    methods: {
        toggleTaskESignatureModal(file) {
            this.taskESignatureModalFile = file;
            this.taskESignatureModal = !this.taskESignatureModal;
        },
        toggleDocumentPreviewer() {
            this.documentPreviewer = !this.documentPreviewer;
        },
        updateEvent() {
            this.$emit('update');
        }
    },
}
</script>

<style lang="scss" scoped>
.scope-upload-contract {
    border-bottom: 1px solid #C3CDD5;
    padding-bottom: 30px;

    h3 {
        font-size: 20px;
        line-height: 1;
        display: flex;
        align-items: center;
    }

    .data {
        line-height: 3;
    }
}
</style>
